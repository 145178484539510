<template>
  <div class="lg:container mx-auto px-6 lg:pb-[40px] lg:mt-12 text-left relative h-full content-end relative">
    <div class="lg:grid lg:grid-cols-12 content-center gap-x-16">
      <div class="container md:col-span-5 xl:col-span-4 2xl:pl-10 flex items-center mx-auto">
        <UiHeadline design="h1" type="h1" class="text-white lg:text-petrol-500 pt-8 lg:pt-12 pb-8 lg:pb-[40px] lg:pt-0" style="font-weight: 900;">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="props.headerDetails?.h1" />
        </UiHeadline>
      </div>
      <div class="md:col-span-7 xl:col-span-8 absolute lg:relative top-0 left-0 w-full z-[-1]">
        <img
          :src="props.headerDetails?.bild_url !== '' ? 'https://img.travanto.de' + props.headerDetails?.bild_url : 'https://img.travanto.de/backgrounds/startseite/www.kiel-travel.de6168.jpeg'"
          alt="Portal"
          loading="eager"
          rel="preload"
          class="object-center object-cover min-h-[310px] max-h-[310px] lg:max-h-[370px] h-full w-full lg:rounded-2xl"
        >
        <div class="absolute lg:hidden top-0 lg:top-auto lg:bottom-0 w-full h-[310px] lg:h-[70%] opacity-75 bg-gradient-to-t from-neutral-800" />
      </div>
    </div>

    <div class="container lg:absolute lg:left-0 lg:bottom-0 w-full mx-auto px-0 lg:px-6 2xl:px-0">
      <div class="2xl:max-w-screen-xl lg:px-6 mx-auto">
        <div class="bg-neutral-50 rounded-xl p-5 w-full shadow-md w-full">
          <RuesSearch :item="props.headerDetails?.search_element.data" />
        </div>
      </div>
    </div>
    <!--<div class="col-span-12 w-full">
      <div class="absolute bottom-0 left-0 -mb-[30px] px-6 w-full">
        <div class="bg-neutral-50 rounded-xl p-5 w-full shadow-md">
          <RuesSearch />
        </div>
      </div>
    </div>-->
  </div>
  <div class="container 2xl:max-w-screen-xl mx-auto grid grid-cols-12 gap-x-4 gap-y-8 px-6 mb-5 mt-8 lg:mt-12 text-left">
    <RuesTrust :trustpilot-box="headerDetails?.trustpilot_box" />
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  headerDetails: {
    type: Object,
    default: null
  }
})
</script>
<style lang="scss">
  .rues-header-for-rues-page-background {
    @apply h-auto lg:h-[370px] w-full;
  }
  .rues-headline-text-shadow {
    text-shadow: 0 2px 3px rgba(0,0,0,.18);
  }
</style>
