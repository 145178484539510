<template>
  <a v-if="magazineLink" :href="magazineLink">
    <img
      :src="magazineLogo"
      :alt="magazineAltText"
      class="container 2xl:max-w-screen-md w-[70%] mx-auto mt-7"
    >
  </a>
</template>

<script setup>
const magazineData = {
  vermieter: {
    link: '/vermieter/magazin/',
    logo: '/svg/logo/Logo_Vermietermagazin_2021.svg',
    alt: 'Vermietermagazin'
  },
  urlaubsmagazin: {
    link: '/urlaubsmagazin/',
    logo: '/svg/logo/Logo_Urlaubsmagazin_2019.svg',
    alt: 'Urlaubsmagazin'
  }
}

const magazineLink = computed(() => magazineData[props.contentGroup]?.link || '')
const magazineLogo = computed(() => magazineData[props.contentGroup]?.logo || '')
const magazineAltText = computed(() => magazineData[props.contentGroup]?.alt || '')

const props = defineProps({
  contentGroup: {
    type: String,
    default: ''
  }
})
</script>
