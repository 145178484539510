<template>
  <RuesMap v-if="props.item?.element === 'Karte'" :item="props.item" />
  <RuesTrust v-if="props.item?.element === 'VertrauensschaffendeMassnahmen'" :item="props.item" />
  <RuesCardExternalSearch v-if="props.item?.element === 'Fahrradteaser'" :item="props.item" />
  <RuesCardExternalSearch v-if="props.item?.element === 'MietwagenSylt'" :item="props.item" />
  <RuesCardReviews v-if="props.item?.element === 'ObjektBewertungLinks'" :item="props.item" />
  <RuesCardNewProduct v-if="props.item?.element === 'NeuesteObjekte'" :item="props.item" />
  <RuesCardReviews v-if="props.item?.element === 'BewertungTeaser'" :item="props.item" />

  <!-- Elements with a Skeleton: -->
  <RuesProductCarousel v-if="props.item?.element === 'Fotolinks'" :skeleton="!props.item?.client" :item="props.item" />
  <RuesCardLastminute v-if="props.item?.element === 'Schweinchenkasten'" :skeleton="!props.item?.client" :item="props.item" />
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
</script>

<style scoped>

</style>
