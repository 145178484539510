<template>
  <div class="border border-neutral-200 rounded-lg hover:shadow-md skeleton-wrapper">
    <div class="grid grid-cols-3 p-4 min-h-[140px] gap-3 lg:gap-8 xl:gap-0 animate-pulse">
      <div class="w-full bg-white rounded-lg overflow-hidden h-[78px] w-[78px] lg:h-[85px] lg:w-[85px] xl:w-[95px] xl:h-[95px]">
        <div class="skeleton w-full h-full" />
      </div>
      <div class="col-span-2">
        <div class="skeleton h-[1.125rem] w-3/4 rounded-full mb-2" />
        <div class="skeleton h-[0.875rem] w-2/3 rounded-full mb-1" />
        <div class="skeleton h-[0.875rem] w-1/2 rounded-full mb-1" />
        <div class="skeleton h-[0.875rem] w-1/3 rounded-full" />
      </div>
    </div>

    <div class="grid grid-cols-2 px-4 pb-3 animate-pulse">
      <div class="skeleton h-[1rem] w-1/2 rounded-full" />
      <div class="text-right">
        <div class="skeleton h-[1.25rem] w-1/2 ml-auto rounded-full" />
      </div>
    </div>

    <div class="border-t border-neutral-200 p-4 animate-pulse">
      <div class="skeleton h-[1rem] w-full mb-2 rounded-full" />
      <div class="skeleton h-[1rem] w-full mb-2 rounded-full" />
      <div class="skeleton h-[1rem] w-full mb-2 rounded-full" />
      <div class="skeleton h-[1rem] w-5/6 rounded-full" />
      <div class="mt-8 flex items-center">
        <div class="skeleton h-[1rem] w-1/3 rounded-full" />
        <div class="skeleton h-[0.75rem] w-[20px] ml-2 rounded-full" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
.skeleton {
  @apply bg-neutral-100;
}

.skeleton-wrapper {
  @apply min-h-[365px];
}
</style>
